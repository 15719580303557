.div-post-login {
  margin-top: 78px;
  margin-left: 80px;
}

@media screen and (max-width: 960px) {
  .div-post-login {
    margin-top: 0;
    margin-left: 0;
    overflow: hidden;
  }
}
