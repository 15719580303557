.react-cookie-law-accept-all-btn {
  position: absolute;
  right: 5%;
  top: 20%;
  /* border-radius: 15%; */
  background-color: #2196f3 !important;
  border: none;
  text-align: center;
  text-decoration: none;
  width: 30px !important;
  height: 20px;
  outline: none;
  background-color: #2196f3;
  color: white;
  font-size: 10px !important;
  border-radius: 5px;
}

.react-cookie-law-option-checkbox {
  display: none;
}

.react-cookie-law-option-optionWrapper {
  display: none;
}

.react-cookie-law-dialog {
}

.Cookie_box {
  position: fixed;
  bottom: 1%;
  left: 0%;
  display: flex;
  width: 100%;
  height: 7%;
  background: White;
  border-radius: 200px;
  align-items: center;
  z-index: 101;
  box-shadow: 0px 0px 8px 3px rgb(131, 130, 130);
}

.Cookie_box > div {
  color: rgb(78, 77, 77);
  font-size: 8px;
}

.Cookie_box > div:nth-child(1) {
  width: 30px;
  height: 50px;
  border-radius: 50%;
  margin: 1%;
}

.Cookie_box > div:nth-child(2) {
}

.Cookie_box > div:nth-child(3) {
  margin-right: 20px;
  margin-left: 80px;
}

.image {
  width: 30px;
  height: 50px;
}

a {
  color: #2196f3;
  background-color: transparent;
  text-decoration: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .react-cookie-law-accept-all-btn {
    right: 5%;
    top: 25px;
    /* border-radius: 15%; */
    width: 90px;
    height: 35px;
    font-size: 15px !important;
  }

  .Cookie_box {
    bottom: 10%;
    left: 26%;
    width: 48%;
    height: 10%;
    box-shadow: 0px 0px 8px 3px rgb(131, 130, 130);
  }

  .Cookie_box > div {
    color: rgb(78, 77, 77);
    font-size: 15px;
  }

  .Cookie_box > div:nth-child(1) {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 1%;
  }

  .Cookie_box > div:nth-child(2) {
  }

  .Cookie_box > div:nth-child(3) {
    margin-right: 20px;
    margin-left: 110px;
  }

  .image {
    width: 50px;
    height: 70px;
  }
}
