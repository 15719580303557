.loginDiv {
  width: 25vw;
  height: 100vh;
  background-color: #3374b9;
  /* background-color: '#3374B9' */
}

@media all and (max-height: 680px) {
  .loginDiv {
    height: 100%;
    /* height: 680px; */
  }
}
